import React, { memo } from 'react'

import * as S from './styles'
import { TagProps } from './types'

export const Component = ({
  size,
  theme,
  intensity,
  color,
  colorText,
  children,
  onClick,
  ...attrs
}: TagProps) => (
  <S.Container hasOnClick={Boolean(onClick)}>
    <S.Tag
      {...attrs}
      size={size}
      theme={theme}
      intensity={intensity}
      color={color}
      colorText={colorText}
      onClick={onClick}
    >
      <S.TagText
        variant={size === 'small' ? 'caption-2' : 'caption-1'}
        theme={theme}
        intensity={intensity}
        colorText={colorText}
        bold
      >
        {children}
      </S.TagText>
    </S.Tag>
  </S.Container>
)

Component.defaultProps = {
  size: 'small',
  theme: 'orange',
  intensity: 'dark',
}

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/data-display-tag-since-3-3-0--simple)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8555-3475&t=27m8lQSsZL5ZIZYU-0)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 *
 * import { Tag } from '@interco/inter-ui/components/Tag'
 *
 * export const MyPage = () => {
 *   return <Tag>Label</Tag>
 * }
 * ```
 */
export const Tag = memo(Component)

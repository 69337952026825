import imageCompression from 'browser-image-compression';
import React from 'react';
import { InterUIFileInputProps } from '../../interfaces/inter-ui-file-input-props';
import { ACCEPTED_FILE_TYPES } from '../../utils/commons';

const DEFAULT_FILE_SIZE_LIMIT = 5242880;

export const InterUIFileInput: React.FC<
  InterUIFileInputProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  onAttach = () => {},
  onError = () => {},
  className,
  accept = '',
  children,
  clearValue,
  hasCompressor,
  limitFileSize,
  sizeLimitValue = DEFAULT_FILE_SIZE_LIMIT,
  acceptedFileTypes,
  ...props
}) => {
  async function handleChange(event: any) {
    event.persist();

    try {
      const files = event.target.files;
      let base64File: string = '';

      if (files && files[0]) {
        if (!fileExtensionIsValid(files[0].type, acceptedFileTypes)) {
          throw new Error('Tipo de arquivo inválido.');
        }

        if (limitFileSize && files[0].size > sizeLimitValue) {
          throw new Error('Arquivo excede o limite de tamanho.');
        }

        const fileIsImage = files[0].type.includes('image');

        if (fileIsImage) {
          base64File = await treatingImage(files[0]);
        } else {
          base64File = await treatingFile(files[0]);
        }
      }
      onAttach(event, base64File);

      if (clearValue) {
        event.target.value = '';
      }
    } catch (err) {
      event.target.value = '';
      onError(err);
    }
  }

  const treatingFile = async (file: File) => {
    let base64File: string = '';

    base64File = await fileToBase64(file);

    return base64File;
  };

  const treatingImage = async (file: File): Promise<string> => {
    let base64File: string = '';

    const options = {
      maxSizeMB: 2.5,
      useWebWorker: true
    };

    if (hasCompressor) {
      const compressedFile = await imageCompression(file, options);
      base64File = await imageCompression.getDataUrlFromFile(compressedFile);
    } else {
      base64File = await fileToBase64(file);
    }

    return base64File;
  };

  const fileExtensionIsValid = (
    fileExtension: string,
    acceptedFileTypes?: string[]
  ) => {
    const shortenExt = fileExtension.replace(/(.*)\//g, '.');
    const typesAccepted = acceptedFileTypes || ACCEPTED_FILE_TYPES;

    if (typesAccepted.includes(shortenExt)) {
      return true;
    }

    return false;
  };

  return (
    <label htmlFor={props.id} className={className}>
      {children}

      <input
        type='file'
        hidden
        data-testid='inter-ui-file-input'
        onChange={handleChange}
        accept={accept}
        {...props}
      />
    </label>
  );
};

/**
 * Converte um objeto `File` retornado pelo input de arquivo em uma string
 * base64.
 */
export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}
